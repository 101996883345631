import * as THREE from "three"
import { convertOffsetToNDCCoordinates, getObjectsToRaycast } from "./UIUtils.mjs"

class InteractableRaycaster {
  interfaceManager
  _raycaster
  _camera
  _pointerNDCCoords = new THREE.Vector2()
  _objectsToRaycast = []
  _intersects = []
  _highlightingColor = new THREE.Color(0x00ff00)
  _currentHoveredData = {
    intersectedObject: null,
    previousColor: new THREE.Color(),
    interactable: null,
  }
  _isRaycastEnabled = true
  _listeners = new Map()

  get hoveredInteractable() {
    return this._currentHoveredData.interactable
  }

  constructor(interfaceManager) {
    this.interfaceManager = interfaceManager
    this._camera = this.interfaceManager.viewer.world.camera

    this._raycaster = new THREE.Raycaster(
      new THREE.Vector3(),
      new THREE.Vector3(0, 0, 1),
      this._camera.near,
      this._camera.far,
    )
    this._raycaster.setFromCamera(new THREE.Vector2(0, 0), this._camera)

    this._listeners.set("pointerenter", () => (this._isRaycastEnabled = true))
    this._listeners.set("pointermove", this.updateRaycasterFromPointerEvent.bind(this))
    this._listeners.set("pointerleave", () => (this._isRaycastEnabled = false))

    this.addListeners()
  }

  addListeners() {
    for (const [k, v] of this._listeners.entries()) {
      this.interfaceManager.viewer.canvas.addEventListener(k, v)
    }
  }

  removeListeners() {
    for (const [k, v] of this._listeners.entries()) {
      this.interfaceManager.viewer.canvas.removeEventListener(k, v)
    }
  }

  clearRaycastables() {
    this._objectsToRaycast = []
  }

  addObjectToRaycastable(object, includeChildren = false, includeSpriteChildren = false) {
    if (includeChildren) {
      this._objectsToRaycast.push(...getObjectsToRaycast(object, includeSpriteChildren))
    } else {
      this._objectsToRaycast.push(object)
    }
  }

  updateRaycasterFromPointerEvent(pointerEvent) {
    if (!(pointerEvent instanceof PointerEvent)) {
      console.error(
        "Event provided to InteractableRaycaster is not a pointer event. Raycaster remains unmodified.",
      )
      return
    }

    const { ndcX, ndcY } = convertOffsetToNDCCoordinates(
      event.offsetX,
      event.offsetY,
      this.interfaceManager.viewer.canvas.clientWidth,
      this.interfaceManager.viewer.canvas.clientHeight,
    )

    this._pointerNDCCoords.set(ndcX, ndcY)

    this._raycaster.setFromCamera(this._pointerNDCCoords, this.interfaceManager.viewer.world.camera)
  }

  update() {
    this.raycast()
  }

  raycast() {
    if (!this._isRaycastEnabled) {
      return
    }

    // console.log('Raycasting...')

    this._intersects.length = 0
    this._raycaster.intersectObjects(this._objectsToRaycast, false, this._intersects)

    if (this._intersects.length == 0) {
      // Unhover previously hovered interactable
      if (this._currentHoveredData.interactable != null) {
        this._currentHoveredData.interactable.setHovered(false)
        this._currentHoveredData.interactable = null
      }
      this._currentHoveredData.intersectedObject = null
    } else {
      let intersected = getIntersectedInteractable.call(this, this._intersects)
      if (!intersected) {
        intersected = { object: this._intersects[0].object }
      }

      if (intersected.object != this._currentHoveredData.intersectedObject) {
        if (this._currentHoveredData.interactable != null) {
          this._currentHoveredData.interactable.setHovered(false)
          this._currentHoveredData.interactable = null
        }

        this._currentHoveredData.intersectedObject = intersected.object
        if (intersected.interactable) {
          intersected.interactable.setHovered(true)
          this._currentHoveredData.interactable = intersected.interactable
        }
      }
    }

    // if (this._currentHoveredData.intersectedObject !== null) {
    //   console.warn(this._currentHoveredData.intersectedObject.name)
    // }
  }
}

function getIntersectedInteractable(intersects) {
  if (intersects.length == 0) {
    return null
  }

  let intersectedInteractable = this.interfaceManager.getInteractableByRaycastable(intersects[0].object)

  if (intersectedInteractable) {
    return { interactable: intersectedInteractable, object: intersects[0].object }
  }

  for (let i = 1; i < intersects.length; i++) {
    intersectedInteractable = this.interfaceManager.getInteractableByRaycastable(intersects[i].object)

    if (intersectedInteractable && intersectedInteractable.ignoreBlockers) {
      return { interactable: intersectedInteractable, object: intersects[i].object }
    }
  }

  return null
}

export default InteractableRaycaster
