import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { Check } from "../../assets/svgr"
import { NEUTRAL_DARK, WHITE } from "../../styles/colors"

export const UIButton = ({
  themeStyle,
  label,
  animateSuccess = false,
  kind = "primary",
  dynamicStyle,
  loading,
  ...props
}) => {
  // animation ---------------------------------------------------------------------------
  const translateKeyframes = {
    "0%": {
      transform: "rotate(180deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  }
  const styles = getStyles({ themeStyle, translateKeyframes, disabled: props?.disabled })

  return (
    <button
      type="button"
      aria-label={label}
      {...props}
      className={css(styles.button, styles[kind], dynamicStyle)}>
      {loading ? (
        <ClipLoader color={WHITE} loading={loading} speedMultiplier={1} size={15} margin={4} />
      ) : (
        <>
          {animateSuccess ? (
            <div
              style={{
                border: "none",
                background: WHITE,
                height: 20,
                width: 20,
                borderRadius: "50%",
              }}
              className={css(styles.animationSuccess)}>
              <Check />
            </div>
          ) : (
            label
          )}
        </>
      )}
    </button>
  )
}

const getStyles = ({ themeStyle, translateKeyframes, disabled }) =>
  StyleSheet.create({
    button: {
      borderRadius: 4,
      fontWeight: 500,
      fontSize: 15,
      cursor: "pointer",
      alignContent: "center",

      ":hover": {
        opacity: disabled ? "1" : "0.6",
      },
      ":disabled": {
        background: NEUTRAL_DARK,
        cursor: "default",
      },
    },
    primary: {
      borderStyle: "none",
      color: WHITE,
      background: themeStyle?.PRIMARY_COLOR,
    },
    secondary: {
      borderStyle: "none",
      color: WHITE,
      background: themeStyle?.SECONDARY_COLOR,
    },
    animationSuccess: {
      animationDuration: "600ms",
      animationFillMode: "forwards",
      animationIterationCount: "initial",
      animationName: [translateKeyframes],
    },
  })

UIButton.propTypes = {
  label: PropTypes.string,
  kind: PropTypes.oneOf(["primary", "secondary"]),
  dynamicStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  themeStyle: PropTypes.object,
  animateSuccess: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
