import * as TWEEN from "@tweenjs/tween.js"
import InteractableRaycaster from "./InteractableRaycaster.mjs"

class ViewerInterfaceManager {
  _enabled = false
  viewer
  _raycaster
  _interactables = new Map()
  _tweenGroup
  _listeners = new Map()
  _clickedInteractable = null

  get tweenGroup() {
    return this._tweenGroup
  }

  constructor(viewer) {
    this.viewer = viewer
    this._tweenGroup = new TWEEN.Group()
    this._raycaster = new InteractableRaycaster(this)

    this._listeners.set("pointerdown", event => {
      this._raycaster.updateRaycasterFromPointerEvent(event)
      this._raycaster.raycast()
      this._clickedInteractable = this._raycaster.hoveredInteractable
    })
    this._listeners.set("click", () => {
      const hoveredInteractable = this._raycaster.hoveredInteractable

      if (hoveredInteractable !== null && this._clickedInteractable == hoveredInteractable) {
        hoveredInteractable.click()
      }
    })
    this.setEnabled(true)
  }

  setEnabled(enabled) {
    if (enabled == this._enabled) {
      return
    }

    this._enabled = enabled ? true : false
    if (this._enabled) {
      this.addListeners()
    } else {
      this.removeListeners()
    }
  }

  addListeners() {
    for (const [k, v] of this._listeners.entries()) {
      this.viewer.canvas.addEventListener(k, v)
    }
  }

  removeListeners() {
    for (const [k, v] of this._listeners.entries()) {
      this.viewer.canvas.removeEventListener(k, v)
    }
  }

  dispose() {
    this.removeListeners()
    this._raycaster.removeListeners()

    this._tweenGroup.removeAll()
    this.clearInterface()
  }

  addInteractable(interactable) {
    // TODO: check validity of interactable (required properties and methods)
    for (const raycastTarget of interactable.raycastTargets) {
      this._interactables.set(raycastTarget, interactable)
    }
    this.viewer.scene.add(interactable.root)

    interactable.addToInterfaceManager(this)
  }

  removeInteractable(interactable) {
    var elementFound = false
    for (const raycastTarget of interactable.raycastTargets) {
      elementFound = elementFound || this._interactables.delete(raycastTarget)
    }

    if (!elementFound) {
      return
    }

    interactable.root.removeFromParent()
    interactable.removeFromInterfaceManager(this)
  }

  clearInterface() {
    const interactablesToRemove = []
    for (const interactable of this._interactables.values()) {
      interactablesToRemove.push(interactable)
    }

    for (const interactable of interactablesToRemove) {
      this.removeInteractable(interactable)
    }

    this._raycaster.clearRaycastables()
  }

  updateInterface() {
    this._raycaster.clearRaycastables()
    const modelRoot = this.viewer.modelRoot
    if (modelRoot !== null) {
      this._raycaster.addObjectToRaycastable(modelRoot, true, false)
    }

    for (const raycastTarget of this._interactables.keys()) {
      this._raycaster.addObjectToRaycastable(raycastTarget)
    }
  }

  getInteractableByRaycastable(raycastTarget) {
    return this._interactables.get(raycastTarget)
  }

  update(deltaTime, timestamp) {
    if (this._enabled) {
      this._raycaster.update()
    }
    this._tweenGroup.update(timestamp)
  }
}

export default ViewerInterfaceManager
