import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Navigate, Route, Routes } from "react-router-dom"
import "./App.css"
import { AuthProvider, ProtectedRoute, ThemeProvider } from "./components/Provider"
import { initSentry } from "./lib/sentry"
import { HomePage, LibraryPage, LoginPage, ManagerPage, ManualsPage, Model3DPage, StepPage } from "./pages"

// INIT i18n
import { useEffect } from "react"
import { useLocation } from "react-use"
import LayoutPage from "./components/Layout/LayoutPage"
import "./i18n"
import { updatePageTitle } from "./lib"

// INIT SENTRY
initSentry()

function App() {
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname.split("/")
    const lastWord = pathname.length > 2 ? pathname[2] : pathname[1]
    updatePageTitle(`${lastWord.charAt(0).toUpperCase() + lastWord.slice(1)} - Guide me`)
  }, [location])

  return (
    <div className="app">
      <ThemeProvider>
        <AuthProvider>
          <DndProvider backend={HTML5Backend}>
            <Routes>
              {/* protect these routes */}
              <Route element={<LayoutPage />}>
                <Route
                  path="/brands"
                  element={
                    <ProtectedRoute>
                      <HomePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manuals"
                  element={
                    <ProtectedRoute>
                      <ManualsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manuals/manager"
                  element={
                    <ProtectedRoute>
                      <ManagerPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manuals/step/new"
                  element={
                    <ProtectedRoute>
                      <StepPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manuals/step/:idstep"
                  element={
                    <ProtectedRoute>
                      <StepPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manuals/model3d"
                  element={
                    <ProtectedRoute>
                      <Model3DPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/library"
                  element={
                    <ProtectedRoute>
                      <LibraryPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/library/:itemId"
                  element={
                    <ProtectedRoute>
                      <LibraryPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/library/*"
                  element={
                    <ProtectedRoute>
                      <LibraryPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/*" element={<Navigate to="/brands" />} />
              </Route>

              {/* public routes */}
              <Route path="/signin" element={<LoginPage />} />
            </Routes>
          </DndProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
